const marketMapping = [
  {
    marketISOCode: 'AR',
    marketName: 'Argentina',
  },
  {
    marketISOCode: 'AT',
    marketName: 'Austria',
  },
  {
    marketISOCode: 'AU',
    marketName: 'Australia',
  },
  {
    marketISOCode: 'BE',
    marketName: 'Belgium',
  },
  {
    marketISOCode: 'BA',
    marketName: 'Bosnia and Herzegovina',
  },
  {
    marketISOCode: 'BR',
    marketName: 'Brazil',
  },
  {
    marketISOCode: 'BG',
    marketName: 'Bulgaria',
  },
  {
    marketISOCode: 'CL',
    marketName: 'Chile',
  },
  {
    marketISOCode: 'CO',
    marketName: 'Colombia',
  },
  {
    marketISOCode: 'HR',
    marketName: 'Croatia',
  },
  {
    marketISOCode: 'CZ',
    marketName: 'Czech Rep.',
  },
  {
    marketISOCode: 'DK',
    marketName: 'Denmark',
  },
  {
    marketISOCode: 'EE',
    marketName: 'Estonia',
  },
  {
    marketISOCode: 'FI',
    marketName: 'Finland',
  },
  {
    marketISOCode: 'FR',
    marketName: 'France',
  },
  {
    marketISOCode: 'DE',
    marketName: 'Germany',
  },
  {
    marketISOCode: 'GH',
    marketName: 'Ghana',
  },
  {
    marketISOCode: 'GB',
    marketName: 'Great Britain',
  },
  {
    marketISOCode: 'HK',
    marketName: 'Hong Kong',
  },
  {
    marketISOCode: 'HU',
    marketName: 'Hungary',
  },
  {
    marketISOCode: 'IE',
    marketName: 'Ireland',
  },
  {
    marketISOCode: 'IT',
    marketName: 'Italy',
  },
  {
    marketISOCode: 'KE',
    marketName: 'Kenia',
  },
  {
    marketISOCode: 'KR',
    marketName: 'Korea',
  },
  {
    marketISOCode: 'LV',
    marketName: 'Latvia',
  },
  {
    marketISOCode: 'LT',
    marketName: 'Lithuania',
  },
  {
    marketISOCode: 'MY',
    marketName: 'Malaysia',
  },
  {
    marketISOCode: 'MX',
    marketName: 'Mexico',
  },
  {
    marketISOCode: 'NL',
    marketName: 'Netherlands',
  },
  {
    marketISOCode: 'NZ',
    marketName: 'New Zealand',
  },
  {
    marketISOCode: 'NO',
    marketName: 'Norway',
  },
  {
    marketISOCode: 'PE',
    marketName: 'Peru',
  },
  {
    marketISOCode: 'PL',
    marketName: 'Poland',
  },
  {
    marketISOCode: 'PT',
    marketName: 'Portugal',
  },
  {
    marketISOCode: 'RO',
    marketName: 'Romania',
  },
  {
    marketISOCode: 'RU',
    marketName: 'Russia',
  },
  {
    marketISOCode: 'SA',
    marketName: 'Saudi Arabia',
  },
  {
    marketISOCode: 'RS',
    marketName: 'Serbia',
  },
  {
    marketISOCode: 'SK',
    marketName: 'Slovakia',
  },
  {
    marketISOCode: 'SG',
    marketName: 'Singapore',
  },
  {
    marketISOCode: 'SI',
    marketName: 'Slovenia',
  },
  {
    marketISOCode: 'ZA',
    marketName: 'South Africa',
  },
  {
    marketISOCode: 'ES',
    marketName: 'Spain',
  },
  {
    marketISOCode: 'SE',
    marketName: 'Sweden',
  },
  {
    marketISOCode: 'CH',
    marketName: 'Switzerland',
  },
  {
    marketISOCode: 'TW',
    marketName: 'Taiwan',
  },
  {
    marketISOCode: 'TZ',
    marketName: 'Tanzania',
  },
  {
    marketISOCode: 'TH',
    marketName: 'Thailand',
  },
  {
    marketISOCode: 'TT',
    marketName: 'Trinidad and Tobago',
  },
  {
    marketISOCode: 'TR',
    marketName: 'Turkey',
  },
  {
    marketISOCode: 'AE',
    marketName: 'UAE',
  },
  {
    marketISOCode: 'US',
    marketName: 'United States of America',
  },
  {
    marketISOCode: 'UA',
    marketName: 'Ukraine',
  },
];

const getOptionsMarkets = () => marketMapping.map(x => { return { key: x.marketISOCode, value: x.marketName } });

export {
  getOptionsMarkets,
};
